import TermsAndConditionsContent from "../../components/termsAndConditionsContent";

export default function termsAndConditions() {
  return (
    <>
      <div className="flex flex-col gap-1 text-center">
        <h1 className="font-bold text-3xl">IntelliPat, Inc</h1>
        <h1 className="font-bold text-3xl">TERMS AND CONDITIONS</h1>
      </div>
      <div className="flex flex-col gap-3 px-6 py-2 overflow-y-auto mb-10">
        <TermsAndConditionsContent />
      </div>
    </>
  );
}
