import { useCallback, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { post } from "aws-amplify/api";
import Loading from "../loading";
import { getStripePublishableKey } from "../../utils/aws-secrets"; // Adjust the path as necessary

type stripeCheckoutFormProps = {
  onSuccess: () => void;
};

const StripeCheckoutForm = ({ onSuccess }: stripeCheckoutFormProps) => {
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<any>(null);

  const fetchClientSecret = useCallback(async () => {
    try {
      const restOperation = post({
        apiName: "stripePaymentApi",
        path: "/create-checkout-session",
        options: {
          body: {
            productType: "novelSearch",
          },
        },
      });
      const { body } = await restOperation.response;
      const response = (await body.json()) as { client_secret: string | null };
      if (response && response.client_secret) {
        setClientSecret(response.client_secret);
      }
      setLoading(false);
      return response;
    } catch (error) {
      console.error("Failed to fetch client secret:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeKey = await getStripePublishableKey();
        const stripe = await loadStripe(stripeKey as string);
        setStripePromise(stripe);
      } catch (error) {
        console.error("Failed to load Stripe:", error);
      }
    };

    initializeStripe();
    fetchClientSecret();
  }, [fetchClientSecret]);

  const handleComplete = () => onSuccess();

  const options = { clientSecret, onComplete: handleComplete };

  return (
    <div id="checkout" className="flex flex-col justify-center my-4 rounded">
      <h1 className="text-2xl font-bold m-auto my-4">Checkout</h1>
      {loading && (
        <div className="m-2">
          <Loading />
        </div>
      )}
      {clientSecret && stripePromise && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default StripeCheckoutForm;
