import { Button, Input } from "@nextui-org/react";
import { confirmSignIn, type ConfirmSignInInput } from "aws-amplify/auth";
import { Dispatch, SetStateAction, useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { MdCheckCircleOutline, MdOutlineDoNotDisturbOn } from "react-icons/md";

export default function changePassUserPassword(props: {
  email: string;
  updateAuthStatus: (arg0: boolean) => void;
  getUser: () => void;
  setCurrentStep: Dispatch<SetStateAction<string>>;
}) {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setNewPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [changePassLoading, setChangePassLoading] = useState(false);
  const [changePassFormError, setChangePassFormError] = useState<string>("");
  const [changePassFormValidation, setChangePassFormValidation] = useState({
    form: false,
    password: false,
    confirmPassword: false,
    matchPassword: false,
    passMinLength: false,
    passHasNumber: false,
    passHasLowerCase: false,
    passHasUpperCase: false,
    passHasSpecialChar: false,
  });

  const validatePassword = (pass: string) => {
    const isSamePassword = password === confirmPassword;
    const isMinLengthValid = pass.length >= 8;
    const isNumberValid = /\d/.test(pass);
    const isLowerCaseValid = /[a-z]/.test(pass);
    const isUpperCaseValid = /[A-Z]/.test(pass);
    const isSpecialCharValid =
      /[\^$*\.\[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/.test(pass) && /^[\S]+.*[\S]+$/.test(pass);

    setChangePassFormValidation((prevState) => ({
      ...prevState,
      password: false,
      confirmPassword: false,
      matchPassword: !isSamePassword,
      passMinLength: !isMinLengthValid,
      passHasNumber: !isNumberValid,
      passHasLowerCase: !isLowerCaseValid,
      passHasUpperCase: !isUpperCaseValid,
      passHasSpecialChar: !isSpecialCharValid,
    }));

    return (
      isSamePassword &&
      isMinLengthValid &&
      isNumberValid &&
      isLowerCaseValid &&
      isUpperCaseValid &&
      isSpecialCharValid
    );
  };

  const validateChangePassForm = () => {
    setChangePassFormError("");
    setChangePassFormValidation((prevState) => ({
      ...prevState,
      password: false,
      confirmPassword: false,
    }));
    const isPasswordValid = validatePassword(password);
    const isNewPasswordValid = validatePassword(confirmPassword);
    setChangePassFormValidation((prevState) => ({
      ...prevState,
      password: !isPasswordValid,
      confirmPassword: !isNewPasswordValid,
    }));
    return isPasswordValid && isNewPasswordValid;
  };
  async function handleChangePassword() {
    if (!validateChangePassForm()) return false;
    setChangePassLoading(true);
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({
        challengeResponse: password,
      } as ConfirmSignInInput);
      if (isSignedIn && nextStep.signInStep === "DONE") {
        props.setCurrentStep("");
        props.updateAuthStatus(true);
        props.getUser();
      }
    } catch (error: any) {
      setChangePassLoading(false);
      setChangePassFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setChangePassFormError(error.message);
    } finally {
      setChangePassLoading(false);
    }
  }
  return (
    <section className="w-[320px] m-auto">
      <h1 className="text-3xl p-5 font-bold flex justify-center">Change Password</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleChangePassword();
        }}
      >
        <Input
          className="mt-5 "
          classNames={{
            label: "text-base",
            input: "text-base",
            errorMessage: "text-md",
          }}
          endContent={
            showPassword ? (
              <IoEyeOff
                className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <IoEye
                className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                onClick={() => setShowPassword(!showPassword)}
              />
            )
          }
          onKeyUp={() => validatePassword(password)}
          onFocus={() => validatePassword(password)}
          label="Password"
          variant="bordered"
          onValueChange={(value) => setPassword(value)}
          value={password}
          type={showPassword ? "text" : "password"}
          isInvalid={changePassFormValidation.password}
          errorMessage={
            password.trim() === ""
              ? "Password is required"
              : changePassFormValidation.password
              ? "Match Password Reqirements"
              : ""
          }
        />
        <Input
          className="mt-5 "
          classNames={{
            label: "text-base",
            input: "text-base",
            errorMessage: "text-md",
          }}
          endContent={
            showNewPassword ? (
              <IoEyeOff
                className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            ) : (
              <IoEye
                className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            )
          }
          onKeyUp={() => validatePassword(confirmPassword)}
          onFocus={() => validatePassword(confirmPassword)}
          label="Confirm Password"
          variant="bordered"
          onValueChange={(value) => setNewPassword(value)}
          value={confirmPassword}
          type={showNewPassword ? "text" : "password"}
          isInvalid={changePassFormValidation.confirmPassword}
          errorMessage={
            confirmPassword.trim() === ""
              ? "Confirm Password is required"
              : changePassFormValidation.confirmPassword
              ? "Match Password Reqirements"
              : ""
          }
        />
        {changePassFormValidation.form && (
          <div className="text-danger text-md pt-2 px-2">{changePassFormError}</div>
        )}
        {(password.length > 0 || confirmPassword.length > 0) && (
          <div className="text-md flex gap-3 flex-col pt-2 px-2">
            <div
              className={
                "flex items-center gap-2 " +
                (changePassFormValidation.matchPassword ? "text-danger" : "text-success")
              }
            >
              {changePassFormValidation.matchPassword ? (
                <MdOutlineDoNotDisturbOn className="w-[5%]" />
              ) : (
                <MdCheckCircleOutline className="w-[5%]" />
              )}
              Passwords does not Match
            </div>

            <div
              className={
                "flex items-center gap-2 " +
                (changePassFormValidation.passMinLength ? "text-danger" : "text-success")
              }
            >
              {changePassFormValidation.passMinLength ? (
                <MdOutlineDoNotDisturbOn className="w-[5%]" />
              ) : (
                <MdCheckCircleOutline className="w-[5%]" />
              )}
              8-character minimum length
            </div>

            <div
              className={
                "flex items-center gap-2 " +
                (changePassFormValidation.passHasNumber ? "text-danger" : "text-success")
              }
            >
              {changePassFormValidation.passHasNumber ? (
                <MdOutlineDoNotDisturbOn className="w-[5%]" />
              ) : (
                <MdCheckCircleOutline className="w-[5%]" />
              )}
              Contains at least 1 number
            </div>

            <div
              className={
                "flex items-center gap-2 " +
                (changePassFormValidation.passHasLowerCase ? "text-danger" : "text-success")
              }
            >
              {changePassFormValidation.passHasLowerCase ? (
                <MdOutlineDoNotDisturbOn className="w-[5%]" />
              ) : (
                <MdCheckCircleOutline className="w-[5%]" />
              )}
              Contains at least 1 lowercase letter
            </div>

            <div
              className={
                "flex items-center gap-2 " +
                (changePassFormValidation.passHasUpperCase ? "text-danger" : "text-success")
              }
            >
              {changePassFormValidation.passHasUpperCase ? (
                <MdOutlineDoNotDisturbOn className="w-[5%]" />
              ) : (
                <MdCheckCircleOutline className="w-[5%]" />
              )}
              Contains at least 1 uppercase letter
            </div>

            <div
              className={
                "flex items-center gap-2 " +
                (changePassFormValidation.passHasSpecialChar ? "text-danger" : "text-success")
              }
            >
              {changePassFormValidation.passHasSpecialChar ? (
                <MdOutlineDoNotDisturbOn className="w-[18%]" />
              ) : (
                <MdCheckCircleOutline className="w-[18%]" />
              )}
              Contains at least 1 special character or a non-leading, non-trailing space character.
              &#94; &#36; &#42; &#46; &#91; &#93; &#123; &#125; &#40; &#41; &#63; - " ! @ # % & / \
              , &#60; &#62; ' : ; | _ ~ ` + =
            </div>
          </div>
        )}
        <Button type="submit" isLoading={changePassLoading} className="my-5 text-base" fullWidth>
          Change Password
        </Button>
      </form>
    </section>
  );
}
