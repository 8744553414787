import {
  getDocumentById,
  addDocument,
  updateDocument,
  getDocSnapshot,
  profileUser,
  getAllUsers,
} from "../lib/firestoreOperations";

const collectionName = "users";

export const createUser = async ({
  data,
  returnRef = false,
}: {
  data: any;
  returnRef?: boolean;
}) => {
  const result = await addDocument(collectionName, data);
  const documentData = { id: result.id, ...getDocSnapshot(result) };
  updateDocument(collectionName, result.id, documentData);
  return returnRef ? result : documentData;
};

export const updateUser = async ({ id, data }: { id: string; data: any }) => {
  return await updateDocument(collectionName, id, data);
};

export const getUserByAuthId = async (uid: string) => {
  return await getDocumentById(collectionName, "auth_id", "==", uid);
};

export const loggerSignIn = async (authUser: any) => {
  const userData = await profileUser(authUser);
  if (userData) {
    await updateDocument(collectionName, userData.id, userData);
  }
};

export const fetchAllUsers = async (rowsPerPage: number, lastVisible: any) => {
  const { users, totalCount, lastVisibleDoc } = await getAllUsers(rowsPerPage, lastVisible);
  console.log("FIRED");
  return { users, totalCount, lastVisibleDoc };
};
