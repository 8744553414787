import { PrimaryFeatureArtSummaryTableProps } from "../types/reportTypes";

import { BiCheck } from "react-icons/bi";
import { BiMinus } from "react-icons/bi";

export default function ArtSummaryTable({
  artSummaryTableData,
}: {
  artSummaryTableData: PrimaryFeatureArtSummaryTableProps;
}) {
  const isFewFeatures = artSummaryTableData.header.length <= 3;
  return (
    <div className={`overflow-x-auto ${isFewFeatures ? "flex justify-center" : ""}`}>
      <table className={`text-sm table-fixed ${isFewFeatures ? "w-auto" : "w-full"}`}>
        <thead className="border-b border-light dark:border-b dark:border-b-dark">
          <tr>
            {artSummaryTableData.header.map((header, id) => (
              <th
                key={id}
                className={`p-3 text-center ${isFewFeatures ? "w-40" : "w-32"} ${
                  id === 0 ? "sticky left-0 z-10 bg-white dark:bg-[hsl(240,5.88%,10%)]" : ""
                }`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {artSummaryTableData.table.map((rowData, rowId) => (
            <tr key={rowId} className="border-b hover:bg-gray-100 dark:hover:bg-gray-800">
              <td className="text-center p-3 sticky left-0 bg-white dark:bg-[hsl(240,5.88%,10%)]">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600"
                  href={rowData.patentLink}
                >
                  {rowData.patentNumber}
                </a>
              </td>
              {rowData.matches.map((match, matchId) => (
                <td key={matchId}>
                  {match === "teach" ? (
                    <div className="flex justify-center">
                      <BiCheck size={25} />
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <BiMinus size={25} />
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
