import SearchInput from "../../../components/SearchInput";
import FeatureList from "../../../components/FeatureList";
import PriorArtTable from "../../../components/PriorArtTable";
import ReportHeader from "../../../components/ReportHeader";
import FeatureSynopsisTable from "../../../components/FeatureSynopsisTable";
import CopyToClipboard from "../../../components/CopyToClipboard";
import {
  getAllInputSearchesAndSubDocuments,
  getAllSearchesAndSubDocuments,
} from "../../../services/searchService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Accordion, AccordionItem } from "@nextui-org/react";
import RadarGraph from "../../../components/radarGraph";
import ArtSummaryTable from "../../../components/artSummaryTable";

export default function ViewReportPage(props: { isAuthenticated: boolean }) {
  const navigate = useNavigate();

  useEffect(() => {
    props.isAuthenticated !== true && navigate("/");
  }, []);

  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<any>({});

  const { id } = useParams();

  useEffect(() => {
    const fetchReport = async () => {
      setPageLoading(true);
      try {
        if (id) {
          const outputData = await getAllSearchesAndSubDocuments(id);
          const inputId = outputData?.mainDocument?.data.input_search_id;
          const inputData = await getAllInputSearchesAndSubDocuments(inputId).then(
            (data) => data?.mainDocument?.data
          );
          let data = {
            inputMetadata: JSON.parse(inputData?.metadata),
            outputMetadata: JSON.parse(outputData?.mainDocument?.data.metadata),
          };
          setReportData(data);
        }
      } catch (error) {
        console.error("Failed to fetch report data:", error);
      } finally {
        setPageLoading(false);
      }
    };
    fetchReport();
  }, [id]);

  if (isPageLoading) {
    return (
      <div className="flex justify-center items-center mt-20">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <ReportHeader
        noveltyScore={reportData.outputMetadata.noveltyScore}
        titleMatter={reportData.inputMetadata.titleMatter}
      />
      {reportData.outputMetadata.summary && (
        <RadarGraph summary={reportData.outputMetadata.summary} />
      )}

      <Accordion variant="splitted" defaultExpandedKeys={["1"]}>
        {reportData.outputMetadata.primaryFeatureArtSummaryTable && (
          <AccordionItem
            aria-label="Accordion 1"
            key="1"
            subtitle="Select to view the art summary table"
            title="Art Summary Table"
          >
            <ArtSummaryTable
              artSummaryTableData={reportData.outputMetadata.primaryFeatureArtSummaryTable}
            ></ArtSummaryTable>
          </AccordionItem>
        )}

        <AccordionItem
          aria-label="Accordion 2"
          key="2"
          subtitle="Select to view the search input"
          title="Search Input"
        >
          <SearchInput inputData={reportData.inputMetadata} />
        </AccordionItem>

        <AccordionItem
          aria-label="Accordion 3"
          key="3"
          subtitle="Select to view primary and secondary features"
          title="Feature List"
        >
          <FeatureList
            primaryFeatures={reportData.outputMetadata.primaryFeatures}
            secondaryFeatures={reportData.outputMetadata.secondaryFeatures}
          />
        </AccordionItem>

        <AccordionItem
          aria-label="Accordion 4"
          key="4"
          subtitle="Select to view the prior art table"
          title="Prior Art Table"
        >
          <PriorArtTable priorArtTableData={reportData.outputMetadata.priorArtTable} />
        </AccordionItem>

        <AccordionItem
          aria-label="Accordion 5"
          key="5"
          subtitle="Select to view the feature synopsis"
          title="Feature Synopsis"
        >
          <FeatureSynopsisTable
            primaryFeatureTable={reportData.outputMetadata.primaryFeatureTable}
            secondaryFeatureTable={reportData.outputMetadata.secondaryFeatureTable}
          />
        </AccordionItem>
      </Accordion>

      <div className="mt-5 pt-2 text-center mb-6">
        <CopyToClipboard patentNumbers={reportData.outputMetadata.priorArtReferences} />
      </div>
    </>
  );
}
