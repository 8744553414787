import { Button, Divider, Input, Link } from "@nextui-org/react";
import {
  resendSignUpCode,
  ResendSignUpCodeInput,
  signInWithRedirect,
  signIn,
  type SignInInput,
} from "aws-amplify/auth";
import { useEffect, useRef, useState } from "react";
import { IoMail, IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ConfirmSignup from "../../components/confirmSignup";
import ChangeUserPassword from "../../components/changeUserPassword";
import ForgotUserPassword from "../../components/forgotUserPassword";
import { post } from "aws-amplify/api";

export default function Login(props: {
  updateAuthStatus: (arg0: boolean) => void;
  getUser: () => void;
  isAuthenticated: boolean;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    props.isAuthenticated === true && navigate("/");
  }, [props.isAuthenticated]);

  const [email, setEmail] = useState<string>("");
  const emailRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [editEmail, setEditEmail] = useState(true);
  const [isGoogleEmailLoading, setIsGoogleEmailLoading] = useState(false);
  const [isGoogleEmail, setIsGoogleEmail] = useState(false);
  const [loginFormError, setLoginFormError] = useState<string>("");
  const [loginFormValidation, setLoginFormValidation] = useState({
    form: false,
    email: false,
    password: false,
  });
  const [currentStep, setCurrentStep] = useState<string>("");

  const checkGoogleEmail = async () => {
    let isGoogle = false;
    try {
      setIsGoogleEmailLoading(true);
      setLoginFormValidation((prevState) => ({
        ...prevState,
        email: false,
        password: false,
      }));
      const isEmailValid = validateEmail(email);
      setLoginFormValidation((prevState) => ({
        ...prevState,
        email: !isEmailValid,
      }));

      if (!isEmailValid) return;
      isGoogle = email.includes("@gmail.com");
      if (isGoogle) {
        setIsGoogleEmail(isGoogle);
        await signInWithRedirect({ provider: "Google", customState: "sign-in" });
      } else {
        const restOperation = post({
          apiName: "intellipatGeneralApi",
          path: "/check-gmail",
          options: {
            body: {
              email: email,
            },
          },
        });
        const { body } = await restOperation.response;
        const response = (await body.json()) as { isGoogleEmail: boolean };
        isGoogle = response.isGoogleEmail;
        setIsGoogleEmail(isGoogle);
        if (isGoogle) {
          await signInWithRedirect({ provider: "Google", customState: "sign-in" });
        }
      }
    } catch (error) {
      console.error("Error checking for google workspace email:", error);
    } finally {
      setIsGoogleEmail(false);
      if (!isGoogle) {
        setEditEmail(false);
      }
      setIsGoogleEmailLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateLoginForm = () => {
    setLoginFormError("");
    setLoginFormValidation((prevState) => ({
      ...prevState,
      email: false,
      password: false,
    }));
    const isEmailValid = validateEmail(email);
    const isPasswordValid = password.trim() !== "";
    setLoginFormValidation((prevState) => ({
      ...prevState,
      email: !isEmailValid,
      password: !isPasswordValid,
    }));
    return isEmailValid && isPasswordValid;
  };

  async function handleSignIn() {
    if (!validateLoginForm()) return false;
    setLoginLoading(true);
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password,
      } as SignInInput);
      if (isSignedIn && nextStep.signInStep === "DONE") {
        props.updateAuthStatus(true);
        props.getUser();
        setLoginLoading(false);
      }
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        await resendSignUpCode({ username: email } as ResendSignUpCodeInput);
        setCurrentStep("CONFIRM_SIGN_UP");
        setLoginLoading(false);
      }
      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        setCurrentStep("CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED");
        setLoginLoading(false);
      }
      if (nextStep.signInStep === "RESET_PASSWORD") {
        setCurrentStep("RESET_PASSWORD");
        setLoginLoading(false);
      }
    } catch (error: any) {
      setLoginLoading(false);
      setLoginFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setLoginFormError(error.message);
    } finally {
      setLoginLoading(false);
    }
  }

  if (currentStep === "CONFIRM_SIGN_UP") {
    return (
      <ConfirmSignup
        email={email}
        updateAuthStatus={props.updateAuthStatus}
        getUser={props.getUser}
        setCurrentStep={setCurrentStep}
      />
    );
  }

  if (currentStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
    return (
      <ChangeUserPassword
        email={email}
        updateAuthStatus={props.updateAuthStatus}
        getUser={props.getUser}
        setCurrentStep={setCurrentStep}
      />
    );
  }

  if (currentStep === "RESET_PASSWORD") {
    return (
      <ForgotUserPassword
        email={email}
        updateAuthStatus={props.updateAuthStatus}
        getUser={props.getUser}
        setCurrentStep={setCurrentStep}
      />
    );
  }

  return (
    <section className="w-[320px] m-auto">
      <h1 className="text-3xl p-10 font-bold flex justify-center">Welcome Back</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSignIn();
        }}
      >
        <Input
          ref={emailRef}
          autoFocus
          disabled={isGoogleEmail}
          isRequired
          classNames={{
            label: "text-base",
            input: "text-base",
            errorMessage: "text-md",
          }}
          endContent={
            <>
              {!editEmail && (
                <Link
                  className="text-light cursor-pointer mr-3"
                  onPress={() => {
                    setEditEmail(true), emailRef.current?.focus();
                    setLoginFormError("");
                  }}
                >
                  Edit
                </Link>
              )}
              <IoMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
            </>
          }
          label="Email address"
          variant="bordered"
          onValueChange={(value) => setEmail(value)}
          value={email}
          isInvalid={loginFormValidation.email}
          errorMessage={
            email.trim() === ""
              ? "Email is required"
              : loginFormValidation.email
              ? "Email is Invalid"
              : ""
          }
        />
        {!isGoogleEmail && editEmail && (
          <Button
            isLoading={isGoogleEmailLoading}
            onPress={checkGoogleEmail}
            className="my-5 text-base"
            fullWidth
          >
            Continue
          </Button>
        )}
        {!editEmail && (
          <>
            <Input
              className="mt-5 "
              classNames={{
                label: "text-base",
                input: "text-base",
                errorMessage: "text-md",
              }}
              endContent={
                showPassword ? (
                  <IoEyeOff
                    className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <IoEye
                    className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )
              }
              label="Password"
              variant="bordered"
              onValueChange={(value) => setPassword(value)}
              value={password}
              type={showPassword ? "text" : "password"}
              isInvalid={loginFormValidation.password}
              errorMessage={loginFormValidation.password ? "Password is required" : ""}
            />
            {loginFormValidation.form && (
              <div className="text-danger text-md pt-2 px-2">{loginFormError}</div>
            )}
            <Link
              className="font-bold underline text-dark mt-3"
              color="primary"
              onPress={() => {
                setCurrentStep("RESET_PASSWORD");
              }}
              size="md"
            >
              Forgot password?
            </Link>
            <Button type="submit" isLoading={loginLoading} className="my-5 text-base" fullWidth>
              Login
            </Button>
          </>
        )}
      </form>
      <div className="flex justify-center gap-2 pb-5">
        <p>Don't have an account?</p>
        <Link href="/signup" className="font-bold underline text-dark cursor-pointer">
          Sign up
        </Link>
      </div>
      <div className="flex justify-center items-center overflow-hidden">
        <Divider />
        <span className="mx-4 text-xs">OR</span>
        <Divider />
      </div>
      <Button
        fullWidth
        className="my-5 text-base"
        startContent={
          <img
            src="https://img.icons8.com/color/48/000000/google-logo.png"
            alt="Google Logo"
            width={24}
            height={24}
          />
        }
        onPress={async () =>
          await signInWithRedirect({ provider: "Google", customState: "sign-in" })
        }
      >
        Continue with Google
      </Button>
    </section>
  );
}
