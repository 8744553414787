import { Button, Input } from "@nextui-org/react";
import {
  autoSignIn,
  confirmSignUp,
  resendSignUpCode,
  type ConfirmSignUpInput,
  type ResendSignUpCodeInput,
} from "aws-amplify/auth";
import { Dispatch, SetStateAction, useState } from "react";

export default function ConfirmSignup(props: {
  email: string;
  updateAuthStatus: (arg0: boolean) => void;
  getUser: () => void;
  setCurrentStep: Dispatch<SetStateAction<string>>;
}) {
  const [confirmCode, setConfirmCode] = useState<string>("");
  const [confirmFormError, setConfirmFormError] = useState<string>("");
  const [confirmFormValidation, setConfirmFormValidation] = useState({
    form: false,
    confirmCode: false,
  });
  const [confirmFormLoading, setConfirmFormLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);

  const validateConfirmForm = () => {
    setConfirmFormValidation((prevState) => ({ ...prevState, confirmCode: false }));
    const isConfirmInputValid = confirmCode.trim() !== "";
    setConfirmFormValidation((prevState) => ({ ...prevState, confirmCode: !isConfirmInputValid }));
    return isConfirmInputValid;
  };

  async function handleSignUpConfirm() {
    if (!validateConfirmForm()) return false;
    setConfirmFormLoading(true);
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: props.email,
        confirmationCode: confirmCode,
      } as ConfirmSignUpInput);
      if (isSignUpComplete && nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
        props.setCurrentStep("");
        handleAutoSignIn();
      }
    } catch (error: any) {
      setConfirmFormLoading(false);
      setConfirmFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setConfirmFormError(error.message);
      console.log("error confirming sign up", error);
    } finally {
      setConfirmFormLoading(false);
    }
  }

  async function handleResendCode() {
    setResendCodeLoading(true);
    try {
      await resendSignUpCode({ username: props.email } as ResendSignUpCodeInput);
    } catch (error: any) {
      setConfirmFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setConfirmFormError(error.message);
      console.log("error re-sending confirmation code", error);
    } finally {
      setResendCodeLoading(false);
    }
  }

  async function handleAutoSignIn() {
    try {
      const signInOutput = await autoSignIn();
      if (signInOutput.isSignedIn) {
        props.updateAuthStatus(true);
        props.getUser();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <section className="w-[320px] m-auto">
      <h1 className="text-3xl px-10 py-5 font-bold flex justify-center">We Emailed You</h1>
      <p className="pb-3">
        Your code is on the way. To log in, enter the code we emailed to {props.email}. It may take
        a minute to arrive.
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSignUpConfirm();
        }}
      >
        <Input
          autoFocus
          isRequired
          classNames={{
            label: "text-base",
            input: "text-base",
            errorMessage: "text-sm",
          }}
          label="Confirmation Code"
          variant="bordered"
          onValueChange={(value) => setConfirmCode(value)}
          value={confirmCode}
          isInvalid={confirmFormValidation.confirmCode}
          errorMessage={
            confirmCode.trim() === ""
              ? "Confirmation code is required"
              : confirmFormValidation.confirmCode
              ? "Confirmation code is Invalid. Please Try again."
              : ""
          }
        />
        {confirmFormValidation.form && (
          <div className="text-danger text-sm pt-2 px-2">{confirmFormError}</div>
        )}
        <Button
          className="mt-2 text-base"
          fullWidth
          isLoading={resendCodeLoading}
          onPress={handleResendCode}
        >
          Resend Code
        </Button>
        <Button
          type="submit"
          isLoading={confirmFormLoading}
          className="my-3 text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
          fullWidth
        >
          Confirm
        </Button>
      </form>
    </section>
  );
}
