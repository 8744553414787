import { fetchAuthSession } from "aws-amplify/auth";
import { SecretsManagerClient, GetSecretValueCommand } from "@aws-sdk/client-secrets-manager";
import { AWSCredentials } from "../types/searchFormTypes";

export const getStripePublishableKey = async () => {
  let stripePublishableKeyName;
  switch (import.meta.env.VITE_ENVIRONMENT) {
    case "dev":
      stripePublishableKeyName = "Intellipat-Dev/stripePublishableKey";
      break;
    case "test":
      stripePublishableKeyName = "Intellipat-Test/stripePublishableKey";
      break;
    case "prod":
      stripePublishableKeyName = "Intellipat-Prod/stripePublishableKey";
      break;
    default:
      throw new Error("Invalid environment setting");
  }

  try {
    // Fetch AWS credentials
    const { credentials } = await fetchAuthSession();
    const { accessKeyId, sessionToken, secretAccessKey } = credentials as AWSCredentials;

    const client = new SecretsManagerClient({
      region: "us-east-2",
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        sessionToken: sessionToken,
      },
    });

    const stripePublishableKey = await client.send(
      new GetSecretValueCommand({ SecretId: stripePublishableKeyName })
    );
    if ("SecretString" in stripePublishableKey) {
      return stripePublishableKey.SecretString;
    }
    throw new Error("Secret not found");
  } catch (error) {
    console.error("Error fetching secret from AWS Secrets Manager:", error);
    throw error;
  }
};
