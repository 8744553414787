import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "@nextui-org/react";
import { updateUser } from "../services/userService";
import { DocumentData } from "firebase/firestore";
import TermsAndConditionsContent from "./termsAndConditionsContent";

type TermsAndConditionsProps = {
  isOpen: any;
  onClose: () => void;
  userDoc: DocumentData | undefined;
};

export default function TermsAndConditions({ isOpen, onClose, userDoc }: TermsAndConditionsProps) {
  const handleTosAccept = async () => {
    await updateUser({ id: userDoc?.id, data: { acceptedTOS: true } });
    onClose();
  };
  return (
    <Modal
      size="5xl"
      scrollBehavior="inside"
      classNames={{ closeButton: "hidden" }}
      backdrop="blur"
      isOpen={isOpen}
      onClose={onClose}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex flex-col gap-1 text-center">
            <h1 className="font-bold text-3xl">IntelliPat, Inc</h1>
            <h1 className="font-bold text-3xl">TERMS AND CONDITIONS</h1>
          </ModalHeader>
          <ModalBody>
            <TermsAndConditionsContent />
          </ModalBody>
          <ModalFooter>
            <Button
              className="text-base max-w-[20%] dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
              onPress={handleTosAccept}
            >
              Accept
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
