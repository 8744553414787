import { Progress } from "@nextui-org/react";

interface ProgressBarProps {
  featureScore: number;
  translateX?: string;
  transform?: boolean;
  type: "novelty" | "coverage";
}

// Function to blend two hex colors based on a percentage
const blendColors = (color1: string, color2: string, percentage: number) => {
  const hex = (color: string) => parseInt(color.slice(1), 16);
  const r = (hex(color2) >> 16) - (hex(color1) >> 16);
  const g = ((hex(color2) >> 8) & 0x00ff) - ((hex(color1) >> 8) & 0x00ff);
  const b = (hex(color2) & 0x0000ff) - (hex(color1) & 0x0000ff);
  const resultColor = `#${(
    ((r * percentage + (hex(color1) >> 16)) << 16) |
    (((g * percentage + (hex(color1) >> 8)) & 0x00ff) << 8) |
    (b * percentage + (hex(color1) & 0x0000ff))
  )
    .toString(16)
    .padStart(6, "0")}`;
  return resultColor;
};

export default function ProgressBar({
  featureScore,
  translateX,
  transform,
  type,
}: ProgressBarProps) {
  const getNoveltyColor = (value: number) => {
    if (value === 0) return "#EA4228"; // Red
    if (value === 50) return "#F5CD19"; // Yellow
    if (value === 100) return "#5BE12C"; // Green
    if (value < 50) {
      const percentage = value / 50;
      return blendColors("#EA4228", "#F5CD19", percentage); // Blend Red to Yellow
    } else {
      const percentage = (value - 50) / 50;
      return blendColors("#F5CD19", "#5BE12C", percentage); // Blend Yellow to Green
    }
  };

  // Function to calculate solid color for coverage score
  const getCoverageColor = (value: number) => {
    if (value === 0) return "#B3B6B7"; // Light gray
    if (value === 50) return "#976D6A"; // Mid-tone Maroon
    if (value === 100) return "#7B241C"; // Maroon
    if (value < 50) {
      const percentage = value / 50;
      return blendColors("#B3B6B7", "#976D6A", percentage); // Light gray to Mid-tone Maroon
    } else {
      const percentage = (value - 50) / 50;
      return blendColors("#976D6A", "#7B241C", percentage); // Blend Mid-tone Maroon to maroon
    }
  };

  const getColorClass = (value: number) => {
    return type == "novelty" ? getNoveltyColor(value) : getCoverageColor(value);
  };

  return (
    <div className={`${transform ? "transform scale-[-1]" : ""}`}>
      <Progress
        size="lg"
        radius="full"
        classNames={{
          track: `drop-shadow-md border border-default`,
          indicator: `bg-[${getColorClass(featureScore)}]`,
          label: "tracking-wider font-medium text-default-600",
        }}
        value={featureScore}
        formatOptions={{ style: "decimal" }}
        aria-label={`Progress indicator with a value of ${featureScore}`}
      />
      <div
        className={`flex items-center mt-[-25px] relative ${
          translateX ? translateX : "translate-x-[-10%]"
        }`}
      >
        <span
          className={`font-bold text-dark dark:bg-black bg-white rounded-full border-2 dark:border-white border-black flex justify-center w-[30px] h-[30px] pb-[1px] items-center  ${
            transform ? "transform scale-[-1]" : ""
          }`}
        >
          {featureScore}
        </span>
      </div>
    </div>
  );
}
