import { 
  addDocument, 
  updateDocumentRef,
  getDocSnapshot 
} from '../lib/firestoreOperations';

const collectionName = "notifications"

export const createUserNotification = async ({ data, returnRef = false, docRef = undefined }: { data: any; returnRef?: boolean; docRef?: any | undefined; }) => {
  const result = await addDocument(collectionName, data, docRef)
  const documentData = { id: result.id, ...getDocSnapshot(result) }
  updateDocumentRef(result, documentData)
  return returnRef ? result : documentData
}
