import { Tooltip } from "@nextui-org/react";
import React, { useState } from "react";
import { ReportHeaderProps } from "../types/reportTypes";
import ProgressBar from "./ProgressBar";
import { IoIosInformationCircleOutline } from "react-icons/io";

const ReportHeader: React.FC<ReportHeaderProps> = ({ noveltyScore, titleMatter }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <header>
      <div className="flex flex-col justify-evenly items-center gap-3 mb-10">
        <h1 className="text-xl font-bold">{titleMatter} Novelty Report</h1>
        <div className="flex justify-center items-center gap-2 w-full">
          <div className="flex flex-wrap gap-2 justify-center items-center">
            <h2 className="text-4xl font-bold">Novelty Score:</h2>
            <div className="pt-4 ps-2 w-52">
              <ProgressBar
                featureScore={noveltyScore}
                translateX="translate-x-[-5%]"
                type="novelty"
              />
            </div>
          </div>
          <Tooltip
            showArrow
            isOpen={isTooltipOpen}
            onOpenChange={(open) => setIsTooltipOpen(open)}
            placement="right"
            content={
              <section>
                <h4 className="text-center font-bold">What does this mean?</h4>
                <ul>
                  <li>Between 0-30, your invention is not likely to be novel.</li>
                  <li>Between 31-70, your invention is potentially novel.</li>
                  <li>Between 71-100, your idea is likely novel.</li>
                </ul>
              </section>
            }
            classNames={{
              base: ["before:bg-[#1e293b] dark:before:bg-[#d1d5db]"],
              content: [
                "py-2 px-4 shadow-xl",
                "text-black text-white dark:bg-[#d1d5db] dark:text-black bg-[#1e293b]",
              ],
            }}
          >
            <div className="pt-2">
              <IoIosInformationCircleOutline
                size={25}
                onClick={() => {
                  setIsTooltipOpen(!isTooltipOpen);
                  setTimeout(() => {
                    setIsTooltipOpen(false);
                  }, 2000);
                }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </header>
  );
};

export default ReportHeader;
