import { Divider } from "@nextui-org/react";
import { FeatureListProps } from "../types/reportTypes";

export default function FeatureList({ primaryFeatures, secondaryFeatures = [] }: FeatureListProps) {
  return (
    <>
      <Divider />
      <div className="mt-5">
        <h2 className="text-xl mb-2 ml-4">Primary Features</h2>
        <ol className="list-decimal list-inside p-4">
          {primaryFeatures.map((priamryFeature, index) => (
            <li
              key={index}
              className={`px-4 py-2 ${
                index < primaryFeatures.length - 1
                  ? "border-b border-gray-200 dark:border-gray-700"
                  : ""
              }`}
            >
              {priamryFeature}
            </li>
          ))}
        </ol>
      </div>

      {secondaryFeatures?.length > 0 && (
        <>
          <Divider />
          <div className="mt-5">
            <h2 className="text-xl mb-2 ml-4">Secondary Features</h2>
            <ol className="list-decimal list-inside p-4">
              {secondaryFeatures.map((secondaryFeature, index) => (
                <li
                  key={index}
                  className={`px-4 py-2 ${
                    index < secondaryFeatures.length - 1
                      ? "border-b border-gray-200 dark:border-gray-700"
                      : ""
                  }`}
                >
                  {secondaryFeature}
                </li>
              ))}
            </ol>
          </div>
        </>
      )}
    </>
  );
}
