import { Button, Divider, Input, Link } from "@nextui-org/react";
import { signInWithRedirect } from "aws-amplify/auth";
import { useEffect, useRef, useState } from "react";
import { signUp, type SignUpInput } from "aws-amplify/auth";
import { IoMail, IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdOutlineDoNotDisturbOn, MdCheckCircleOutline } from "react-icons/md";
import ConfirmSignup from "../../components/confirmSignup";
import { post } from "aws-amplify/api";

export default function Signup(props: {
  updateAuthStatus: (arg0: boolean) => void;
  getUser: () => void;
  isAuthenticated: boolean;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    props.isAuthenticated === true && navigate("/");
  }, [props.isAuthenticated]);

  const [email, setEmail] = useState<string>("");
  const emailRef = useRef<HTMLInputElement>(null);
  const [newPassword, setNewPassword] = useState<string>("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [editEmail, setEditEmail] = useState(true);
  const [isGoogleEmailLoading, setIsGoogleEmailLoading] = useState(false);
  const [isGoogleEmail, setIsGoogleEmail] = useState(false);
  const [signupFormError, setSignupFormError] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<string>("");
  const [signupFormValidation, setSignupFormValidation] = useState({
    form: false,
    email: false,
    newPassword: false,
    passMinLength: false,
    passHasNumber: false,
    passHasLowerCase: false,
    passHasUpperCase: false,
    passHasSpecialChar: false,
  });

  const checkGoogleEmail = async () => {
    let isGoogle = false;
    try {
      setIsGoogleEmailLoading(true);
      setSignupFormValidation((prevState) => ({
        ...prevState,
        email: false,
        newPassword: false,
      }));
      const isEmailValid = validateEmail(email);
      setSignupFormValidation((prevState) => ({
        ...prevState,
        email: !isEmailValid,
      }));

      if (!isEmailValid) return;
      isGoogle = email.includes("@gmail.com");
      if (isGoogle) {
        setIsGoogleEmail(isGoogle);
        await signInWithRedirect({ provider: "Google", customState: "sign-in" });
      } else {
        const restOperation = post({
          apiName: "intellipatGeneralApi",
          path: "/check-gmail",
          options: {
            body: {
              email: email,
            },
          },
        });
        const { body } = await restOperation.response;
        const response = (await body.json()) as { isGoogleEmail: boolean };
        isGoogle = response.isGoogleEmail;
        setIsGoogleEmail(isGoogle);
        if (isGoogle) {
          await signInWithRedirect({ provider: "Google", customState: "sign-in" });
        }
      }
    } catch (error) {
      console.error("Error checking for google workspace email:", error);
    } finally {
      setIsGoogleEmail(false);
      if (!isGoogle) {
        setEditEmail(false);
      }
      setIsGoogleEmailLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateNewPassword = (password: string) => {
    const isMinLengthValid = password.length >= 8;
    const isNumberValid = /\d/.test(password);
    const isLowerCaseValid = /[a-z]/.test(password);
    const isUpperCaseValid = /[A-Z]/.test(password);
    const isSpecialCharValid =
      /[\^$*\.\[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/.test(password) && /^[\S]+.*[\S]+$/.test(password);

    setSignupFormValidation((prevState) => ({
      ...prevState,
      newPassword: false,
      passMinLength: !isMinLengthValid,
      passHasNumber: !isNumberValid,
      passHasLowerCase: !isLowerCaseValid,
      passHasUpperCase: !isUpperCaseValid,
      passHasSpecialChar: !isSpecialCharValid,
    }));

    return (
      isMinLengthValid &&
      isNumberValid &&
      isLowerCaseValid &&
      isUpperCaseValid &&
      isSpecialCharValid
    );
  };

  const validateSignupForm = () => {
    setSignupFormError("");
    setSignupFormValidation((prevState) => ({
      ...prevState,
      email: false,
      newPassword: false,
    }));
    const isEmailValid = validateEmail(email);
    const isNewPasswordValid = validateNewPassword(newPassword);
    setSignupFormValidation((prevState) => ({
      ...prevState,
      email: !isEmailValid,
      newPassword: !isNewPasswordValid,
    }));
    return isEmailValid && isNewPasswordValid;
  };

  async function handleSignUp() {
    if (!validateSignupForm()) return false;
    setSignupLoading(true);
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email,
        password: newPassword,
        options: {
          userAttributes: {
            email: email,
          },
          autoSignIn: true,
        },
      } as SignUpInput);
      if (isSignUpComplete && nextStep.signUpStep === "DONE") {
        props.updateAuthStatus(true);
        props.getUser();
        setSignupLoading(false);
      }
      if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        setCurrentStep("CONFIRM_SIGN_UP");
        setSignupLoading(false);
      }
    } catch (error: any) {
      setSignupLoading(false);
      setSignupFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setSignupFormError(error.message);
    }
  }
  if (currentStep === "CONFIRM_SIGN_UP") {
    return (
      <ConfirmSignup
        email={email}
        updateAuthStatus={props.updateAuthStatus}
        getUser={props.getUser}
        setCurrentStep={setCurrentStep}
      />
    );
  }

  return (
    <section className="w-[320px] m-auto">
      <h1 className="text-3xl px-5 py-10 font-bold flex justify-center">Create an account</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSignUp();
        }}
      >
        <Input
          ref={emailRef}
          autoFocus
          disabled={isGoogleEmail}
          isRequired
          classNames={{
            label: "text-base",
            input: "text-base",
            errorMessage: "text-md",
          }}
          endContent={
            <>
              {!editEmail && (
                <Link
                  className="text-light cursor-pointer mr-3"
                  onPress={() => {
                    setEditEmail(true), emailRef.current?.focus();
                    setSignupFormError("");
                  }}
                >
                  Edit
                </Link>
              )}
              <IoMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
            </>
          }
          label="Email address"
          variant="bordered"
          onValueChange={(value) => setEmail(value)}
          value={email}
          isInvalid={signupFormValidation.email}
          errorMessage={
            email.trim() === ""
              ? "Email is required"
              : signupFormValidation.email
              ? "Email is Invalid"
              : ""
          }
        />
        {!isGoogleEmail && editEmail && (
          <Button
            isLoading={isGoogleEmailLoading}
            onPress={checkGoogleEmail}
            className="my-5 text-base"
            fullWidth
          >
            Continue
          </Button>
        )}
        {!editEmail && (
          <>
            <Input
              className="mt-5 "
              classNames={{
                label: "text-base",
                input: "text-base",
                errorMessage: "text-md",
              }}
              endContent={
                showNewPassword ? (
                  <IoEyeOff
                    className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                ) : (
                  <IoEye
                    className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                )
              }
              onKeyUp={() => validateNewPassword(newPassword)}
              label="Create Password"
              variant="bordered"
              onValueChange={(value) => setNewPassword(value)}
              value={newPassword}
              type={showNewPassword ? "text" : "password"}
              isInvalid={signupFormValidation.newPassword}
              errorMessage={
                newPassword.trim() === ""
                  ? "Password is required"
                  : signupFormValidation.newPassword
                  ? "Match Password Reqirements"
                  : ""
              }
            />
            {signupFormValidation.form && (
              <div className="text-danger text-md pt-2 px-2">{signupFormError}</div>
            )}
            {newPassword.length > 0 && (
              <div className="text-md flex gap-3 flex-col pt-2 px-2">
                <div
                  className={
                    "flex items-center gap-2 " +
                    (signupFormValidation.passMinLength ? "text-danger" : "text-success")
                  }
                >
                  {signupFormValidation.passMinLength ? (
                    <MdOutlineDoNotDisturbOn className="w-[5%]" />
                  ) : (
                    <MdCheckCircleOutline className="w-[5%]" />
                  )}
                  8-character minimum length
                </div>

                <div
                  className={
                    "flex items-center gap-2 " +
                    (signupFormValidation.passHasNumber ? "text-danger" : "text-success")
                  }
                >
                  {signupFormValidation.passHasNumber ? (
                    <MdOutlineDoNotDisturbOn className="w-[5%]" />
                  ) : (
                    <MdCheckCircleOutline className="w-[5%]" />
                  )}
                  Contains at least 1 number
                </div>

                <div
                  className={
                    "flex items-center gap-2 " +
                    (signupFormValidation.passHasLowerCase ? "text-danger" : "text-success")
                  }
                >
                  {signupFormValidation.passHasLowerCase ? (
                    <MdOutlineDoNotDisturbOn className="w-[5%]" />
                  ) : (
                    <MdCheckCircleOutline className="w-[5%]" />
                  )}
                  Contains at least 1 lowercase letter
                </div>

                <div
                  className={
                    "flex items-center gap-2 " +
                    (signupFormValidation.passHasUpperCase ? "text-danger" : "text-success")
                  }
                >
                  {signupFormValidation.passHasUpperCase ? (
                    <MdOutlineDoNotDisturbOn className="w-[5%]" />
                  ) : (
                    <MdCheckCircleOutline className="w-[5%]" />
                  )}
                  Contains at least 1 uppercase letter
                </div>

                <div
                  className={
                    "flex items-center gap-2 " +
                    (signupFormValidation.passHasSpecialChar ? "text-danger" : "text-success")
                  }
                >
                  {signupFormValidation.passHasSpecialChar ? (
                    <MdOutlineDoNotDisturbOn className="w-[18%]" />
                  ) : (
                    <MdCheckCircleOutline className="w-[18%]" />
                  )}
                  Contains at least 1 special character or a non-leading, non-trailing space
                  character. &#94; &#36; &#42; &#46; &#91; &#93; &#123; &#125; &#40; &#41; &#63; - "
                  ! @ # % & / \ , &#60; &#62; ' : ; | _ ~ ` + =
                </div>
              </div>
            )}
            <Button type="submit" isLoading={signupLoading} className="my-5 text-base" fullWidth>
              Signup
            </Button>
          </>
        )}
      </form>
      <div className="flex justify-center gap-2 pb-5">
        <p>Already have an account?</p>
        <Link href="/login" className="font-bold underline text-dark cursor-pointer">
          Login
        </Link>
      </div>
      <div className="flex justify-center items-center overflow-hidden">
        <Divider />
        <span className="mx-4 text-xs">OR</span>
        <Divider />
      </div>
      <Button
        fullWidth
        className="my-5 text-base"
        startContent={
          <img
            src="https://img.icons8.com/color/48/000000/google-logo.png"
            alt="Google Logo"
            width={24}
            height={24}
          />
        }
        onPress={async () =>
          await signInWithRedirect({ provider: "Google", customState: "sign-in" })
        }
      >
        Continue with Google
      </Button>
    </section>
  );
}
