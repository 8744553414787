import { Button } from "@nextui-org/button";
import { useState } from "react";
import { getAllSearchesAndSubDocuments } from "../services/searchService";
import ReportPdf from "./ReportPdf";
import { Download } from "./icons";
import { Spinner } from "@nextui-org/react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { ReportData } from "../types/reportTypes";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DownloadJsonButton2 = ({ item, fileName }: { item: any; fileName: string }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generatePdfDocument = async (fileName: string, reportData: ReportData) => {
    const blob = await pdf(<ReportPdf reportData={reportData} />).toBlob();
    saveAs(blob, fileName);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const mainDocument = await getAllSearchesAndSubDocuments(item.id);
      if (mainDocument && mainDocument.mainDocument && mainDocument.mainDocument.data.metadata) {
        const metadata = mainDocument.mainDocument.data.metadata;
        const jsonData = JSON.parse(metadata);
        await generatePdfDocument(`${fileName}.pdf`, jsonData);
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    fetchData();
  };

  return (
    <div>
      {item.status === "done" && (
        <Button isIconOnly size="sm" onClick={handleDownload} disabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : <Download />}
        </Button>
      )}
    </div>
  );
};

export default DownloadJsonButton2;
