import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

interface LoadInventionModalProps {
  isOpen: boolean;
  inventionTitle: string | undefined;
  onClose: () => void;
  onConfirm: () => void;
}

export default function LoadInventionModal({
  isOpen,
  inventionTitle,
  onClose,
  onConfirm,
}: LoadInventionModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} backdrop="blur">
      <ModalContent>
        <ModalHeader>Confirm Overwrite</ModalHeader>
        <ModalBody>
          <p>
            You are about to overwrite your previously saved invention with the following title:
          </p>
          <p className="font-bold text-lg">{inventionTitle}</p>
          <p>Are you sure you want to proceed? This action cannot be undone.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="text-base max-w-[20%] dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
