import "../styles/globals.css";
import { Helmet } from "react-helmet";
import { siteConfig } from "../config/site";
import { Providers } from "./providers";
import NavbarIntellipat from "../components/navbar";
import React, { Suspense } from "react";
import Loading from "./loading";

export default function RootLayout({
  isAuthenticated,
  updateAuthStatus,
  userIdentity,
  adminUser,
  children,
}: {
  isAuthenticated: boolean;
  updateAuthStatus: (authStatus: boolean) => void;
  userIdentity: any;
  adminUser: boolean;
  children: React.ReactNode;
}) {
  return (
    <Providers themeProps={{ attribute: "class", defaultTheme: "light" }}>
      <Helmet>
        <title>{siteConfig.name}</title>
        <meta name="description" content={siteConfig.description} />
      </Helmet>
      <div className="flex justify-center">
        <NavbarIntellipat
          isAuthenticated={isAuthenticated}
          updateAuthStatus={updateAuthStatus}
          userIdentity={userIdentity}
          adminUser={adminUser}
        />
      </div>
      <div className="relative flex flex-col h-full">
        <main className="container mx-auto max-w-7xl pt-5 px-6 flex-grow">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </main>
      </div>
    </Providers>
  );
}
