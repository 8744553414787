import { Button } from "@nextui-org/react";
import { createUser, getUserByAuthId } from "../services/userService";
import { createUserNotification } from "../services/notificationService";
import { useEffect } from "react";
import { getCustomTokenFromServer } from "../lib/firebase-config";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { getDocSnapshot } from "../lib/firestoreOperations";
import { useNavigate } from "react-router-dom";
import UserAvatar from "./userAvatar";

export default function Auth(props: {
  isAuthenticated: boolean;
  userIdentity: any;
  onOpen: () => void;
  setUserDoc: (arg0: DocumentData) => void;
  adminUser: boolean;
  updateAuthStatus: (arg0: boolean) => void;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isAuthenticated && props.userIdentity) {
      findUserRecord(props.userIdentity);
    }
  }, [props.isAuthenticated, props.userIdentity]);

  async function findUserRecord(user: any) {
    await getCustomTokenFromServer(user);
    let userDoc = await getUserByAuthId(user.sub);
    if (userDoc === undefined) {
      userDoc = (await createUserRecord(user)) as DocumentData;
    }
    checkTos(user);
  }

  async function createUserRecord(user: any) {
    const payload = {
      auth_id: user.sub,
      name: user.name ? user.name : "",
      email: user.email,
      photo_url: user.picture ? user.picture : "",
      acceptedTOS: false,
    };
    const userSnapshot = await createUser({ data: payload, returnRef: true })
      .then(async (docRef) => {
        await createUserNotification({
          data: {
            title: `Welcome ${payload.name}, thank you for joining intelliPat AI.`,
            message: `Welcome ${payload.name}, thank you for joining intelliPat AI.`,
            type: "info",
          },
          docRef: docRef,
        });
        return getDocSnapshot(docRef as DocumentReference);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    return userSnapshot;
  }

  const checkTos = async (user: { sub: string | undefined }): Promise<void> => {
    if (user.sub !== undefined) {
      let userDoc = await getUserByAuthId(user.sub);
      if (userDoc) {
        if (userDoc.acceptedTOS === false || userDoc.acceptedTOS === undefined) {
          props.onOpen();
        }
        if (props.setUserDoc) {
          props.setUserDoc(userDoc);
        }
      }
    }
  };

  return !props.isAuthenticated ? (
    <Button
      className="text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
      onPress={() => {
        navigate("/login");
      }}
    >
      Login
    </Button>
  ) : (
    <UserAvatar
      isAuthenticated={props.isAuthenticated}
      userIdentity={props.userIdentity}
      adminUser={props.adminUser}
      updateAuthStatus={props.updateAuthStatus}
    />
  );
}
