import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import AdditionalFeatureDetails from "./AdditionalFeatureDetails";
import { PrimaryFeatureTableProps, SecondaryFeatureTableProps } from "../types/reportTypes";
import ProgressBar from "./ProgressBar";

interface AllFeatureTableProps extends PrimaryFeatureTableProps, SecondaryFeatureTableProps {}

export default function PriorArtTable({
  primaryFeatureTable,
  secondaryFeatureTable = [],
}: AllFeatureTableProps) {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleExpansion = (patentNumber: string, isPrimary: boolean, index: number) => {
    const prefixedPatentNumber = `${isPrimary ? "primary" : "secondary"}-${patentNumber}-${index}`;
    setExpandedRow(expandedRow === prefixedPatentNumber ? null : prefixedPatentNumber);
  };

  const truncateInventors = (inventors: string[]): string => {
    if (inventors.length > 3) {
      return inventors[0] + " et al.";
    } else {
      const paddedInventors = inventors.map((inventor) => " " + inventor);
      return paddedInventors.toString();
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="text-sm table-fixed w-full">
        <thead className="border-b border-light dark:border-b dark:border-b-dark">
          <tr>
            <th className="p-3 w-[10%]">Feature</th>
            <th className="p-3 w-[10%]">Coverage</th>
            <th className="p-3 w-[15%]">Patent Number</th>
            <th className="p-3 w-[30%]">Title</th>
            <th className="p-3 w-[35%]">Inventor(s)</th>
          </tr>
        </thead>

        <tbody>
          {primaryFeatureTable.map((primaryFeature, index) => (
            <React.Fragment key={`primary-${primaryFeature.patentNumber}-${index}`}>
              <tr
                className={`border-b cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 ${
                  expandedRow === `primary-${primaryFeature.patentNumber}-${index}`
                    ? "border-b-0"
                    : ""
                } last:border-b-0`}
                onClick={() => toggleExpansion(primaryFeature.patentNumber, true, index)}
              >
                <td className="text-center p-3 font-bold">P-{index + 1}</td>
                <td className="text-center p-3">
                  <ProgressBar
                    featureScore={primaryFeature.coverage}
                    transform={true}
                    translateX="translate-x-[-22%]"
                    type="coverage"
                  />
                </td>
                <td className="text-center p-3">{primaryFeature.patentNumber}</td>
                <td className="text-center p-3">{primaryFeature.title}</td>
                <td className="text-center p-3">{truncateInventors(primaryFeature.inventors)}</td>
              </tr>
              <AnimatePresence>
                {expandedRow === `primary-${primaryFeature.patentNumber}-${index}` && (
                  <tr className="">
                    <td colSpan={6} className="p-3  w-full">
                      <div className="overflow-hidden m-0 w-[100%]">
                        <AdditionalFeatureDetails featureDetail={primaryFeature} />
                      </div>
                    </td>
                  </tr>
                )}
              </AnimatePresence>
            </React.Fragment>
          ))}
          {secondaryFeatureTable.map((secondaryFeature, index) => (
            <React.Fragment key={`secondary-${secondaryFeature.patentNumber}-${index}`}>
              <tr
                className={`border-b cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 ${
                  expandedRow === `secondary-${secondaryFeature.patentNumber}-${index}`
                    ? "border-b-0"
                    : ""
                } last:border-b-0`}
                onClick={() => toggleExpansion(secondaryFeature.patentNumber, false, index)}
              >
                <td className="text-center p-3 font-bold">S-{index + 1}</td>
                <td className="text-center p-3">
                  <ProgressBar
                    featureScore={secondaryFeature.coverage}
                    transform={true}
                    translateX="translate-x-[-22%]"
                    type="coverage"
                  />
                </td>
                <td className="text-center p-3">{secondaryFeature.patentNumber}</td>
                <td className="text-center p-3">{secondaryFeature.title}</td>
                <td className="text-center p-3">{secondaryFeature.inventors}</td>
              </tr>
              <AnimatePresence>
                {expandedRow === `secondary-${secondaryFeature.patentNumber}-${index}` && (
                  <tr className="">
                    <td colSpan={6} className="p-3  w-full">
                      <div className="overflow-hidden m-0 w-[100%]">
                        <AdditionalFeatureDetails featureDetail={secondaryFeature} />
                      </div>
                    </td>
                  </tr>
                )}
              </AnimatePresence>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
