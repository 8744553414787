import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { checkIsAdminUser } from "./lib/firestoreOperations";

import Page from "./pages/page";
import Login from "./pages/auth/loginPage.tsx";
// import Dashboard from "./pages/dashboard/page";
import Search from "./pages/search/page";
// import TestPage from "./pages/testPage";
import TermsAndConditions from "./pages/terms/termsAndConditions.tsx";
import Reports from "./pages/search/Reports/ReportListpage.tsx";
import ViewReport from "./pages/search/viewReport/ViewReportPage";
import Settings from "./pages/settings/page";
// import Coupons from "./pages/coupons/page";
import Admin from "./pages/admin/adminPage";
import { getCustomTokenFromServer } from "./lib/firebase-config";
import RootLayout from "./pages/layout.tsx";
import Signup from "./pages/auth/signupPage.tsx";
import { CognitoUser, TokenPayload } from "./types/cognitoUserType.ts";
import PageNotFound from "./pages/pageNotFound";
import { Spinner } from "@nextui-org/react";
// import Billing from "./pages/billing/page";
// import IDS from "./pages/ids/page";

const App: React.FC = () => {
  const [adminUser, setAdminUser] = useState<boolean>(false);
  const [userIdentity, setUserIdentity] = useState<CognitoUser>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated") === "true";
    setIsAuthenticated(authStatus);
    getUser();
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        const { tokens } = await fetchAuthSession();
        const userIdentity = tokens?.idToken?.payload as TokenPayload;
        setUserIdentity(userIdentity as CognitoUser);
        updateAuthStatus(true);
        await getCustomTokenFromServer(userIdentity);
        if (userIdentity && userIdentity.email) {
          const isAdminUser = await checkIsAdminUser(userIdentity.sub);
          if (isAdminUser) {
            setAdminUser(true);
          }
        }
        localStorage.setItem("isAuthenticated", "true");
      }
    } catch (error) {
      setIsAuthenticated(false);
      localStorage.setItem("isAuthenticated", "false");
    } finally {
      setIsLoading(false);
    }
  };

  function updateAuthStatus(authStatus: boolean | ((prevState: boolean) => boolean)) {
    setIsAuthenticated(authStatus);
    localStorage.setItem("isAuthenticated", authStatus.toString());
  }

  if (isLoading) {
    return (
      <>
        <Router>
          <RootLayout
            isAuthenticated={isAuthenticated}
            updateAuthStatus={updateAuthStatus}
            userIdentity={userIdentity}
            adminUser={adminUser}
          >
            <Routes>
              <Route path="/" element={<Page />} />
            </Routes>
          </RootLayout>
        </Router>
        <Spinner className="flex h-[50vh]" size="lg" />
      </>
    );
  }

  return (
    <Router>
      <RootLayout
        isAuthenticated={isAuthenticated}
        updateAuthStatus={updateAuthStatus}
        userIdentity={userIdentity}
        adminUser={adminUser}
      >
        <Routes>
          <Route path="/" element={<Page />} />
          <Route
            path="/login"
            element={
              <Login
                updateAuthStatus={updateAuthStatus}
                isAuthenticated={isAuthenticated}
                getUser={getUser}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup
                updateAuthStatus={updateAuthStatus}
                isAuthenticated={isAuthenticated}
                getUser={getUser}
              />
            }
          />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/ids" element={<IDS />} /> */}
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route
            path="/search"
            element={<Search isAuthenticated={isAuthenticated} userIdentity={userIdentity} />}
          />
          {/* <Route path="/test-page" element={<TestPage />} /> */}
          <Route
            path="/search/Reports"
            element={<Reports isAuthenticated={isAuthenticated} userIdentity={userIdentity} />}
          />
          <Route
            path="/search/Reports/:id"
            element={<ViewReport isAuthenticated={isAuthenticated} />}
          />
          {/* <Route path="/billing" element={<Billing />} /> */}
          <Route path="/settings" element={<Settings isAuthenticated={isAuthenticated} />} />
          {adminUser ? (
            // <Route
            //   path="/generate-coupons"
            //   element={<Coupons isAuthenticated={isAuthenticated} />}
            // />
            <Route
              path="/admin"
              element={<Admin isAuthenticated={isAuthenticated} userIdentity={userIdentity} />}
            />
          ) : null}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </RootLayout>
    </Router>
  );
};

export default App;
