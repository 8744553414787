import { motion } from "framer-motion";
import { AdditionalDetailsProps } from "../types/reportTypes";

export default function AdditionalDetails({ patentDetail }: AdditionalDetailsProps) {
  const currentEnv = import.meta.env.VITE_ENVIRONMENT;
  const enforceHttps = (url: string) => {
    if (url.startsWith("https://")) return url;
    return url.replace(/^http:\/\//i, "https://");
  };
  const shapeSimScore = (simScore: number) => {
    return Math.round(simScore * 100);
  };

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3, ease: "linear" }}
    >
      <div className="p-3 border-b">
        <h3 className="text-lg">
          Additional details about feature{" "}
          {patentDetail.patentLink ? (
            <a
              href={enforceHttps(patentDetail.patentLink)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600"
            >
              {patentDetail.patentNumber}
            </a>
          ) : (
            patentDetail.patentNumber
          )}
          :
        </h3>

        {patentDetail.detail.map((detailItem, detailIndex) => (
          <div key={detailIndex}>
            <h4 className="pt-7 pb-3 font-bold">{detailItem.feature}</h4>
            {detailItem.content.map((item, itemIndex) => (
              <p key={itemIndex}>
                {currentEnv === "test"
                  ? "(" + shapeSimScore(item.similarity) + ")" + " " + item.detailForFeature
                  : item.detailForFeature}
              </p>
            ))}
          </div>
        ))}
      </div>
    </motion.div>
  );
}
