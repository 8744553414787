import { Spinner } from "@nextui-org/react";

const Loading = () => {
  document.title = "IntelliPat.ai"; // Update document title directly

  return (
    <div className="flex flex-col items-center justify-center min-h-full py-2">
      <link rel="icon" href="/favicon.ico" />
      <main className="flex flex-col items-center justify-center flex-1 px-10 text-center">
        <Spinner />
      </main>
    </div>
  );
};

export default Loading;
