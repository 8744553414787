import { initializeApp } from "firebase/app";
import { getApps, getApp } from "firebase/app";
import { Auth, getAuth, signInWithCustomToken } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from "../service_accounts/firebase-config.json";
import { post } from "aws-amplify/api";

const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Function to authenticate with Firebase using custom token
async function authenticateWithCustomToken(auth: Auth, customToken: string) {
  try {
    await signInWithCustomToken(auth, customToken);
  } catch (error) {
    console.error("Error authenticating with custom token :", error);
  }
}

export async function getCustomTokenFromServer(user) {
  try {
    const restOperation = post({
      apiName: "firebaseAuth",
      path: "/authFirebase",
      options: {
        body: {
          uid: user.sub,
        },
      },
    });
    const { body } = await restOperation.response;
    const response = (await body.json()) as { customToken: string };
    const customToken = response.customToken;
    await authenticateWithCustomToken(auth, customToken);
  } catch (error) {
    console.error("Error getting custom token from server:", error);
  }
}

export { auth, provider, db };
