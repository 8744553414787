import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { PriorArtTableProps } from "../types/reportTypes";
import AbstractText from "./AbstractText";
import AdditionalDetails from "./AdditionalDetails";
import ProgressBar from "./ProgressBar";

export default function PriorArtTable({ priorArtTableData }: PriorArtTableProps) {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleExpansion = (patentNumber: string) => {
    setExpandedRow(expandedRow === patentNumber ? null : patentNumber);
  };

  const truncateInventors = (inventors: string): string => {
    let counter = 0;
    for (let i = 0; i < inventors.length; i++) {
      if (inventors[i] == ",") counter++;
      if (counter === 3) break;
    }
    if (counter < 3) {
      return inventors;
    } else {
      const firstComma = inventors.indexOf(",");
      return inventors.substring(0, firstComma) + " et al.";
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="text-sm table-fixed w-full">
        <thead className="border-b border-light dark:border-b dark:border-b-dark">
          <tr>
            <th className="p-3 w-[10%]">Coverage</th>
            <th className="p-3 w-[10%]">Patent #</th>
            <th className="p-3 w-[15%]">Title</th>
            <th className="p-3 w-[15%]">Inventor(s)</th>
            <th className="p-3 w-[43%]">Abstract</th>
            <th className="p-3 w-[7%]">Match</th>
          </tr>
        </thead>

        <tbody>
          {priorArtTableData.map((patent) => (
            <React.Fragment key={patent.patentNumber}>
              <tr
                className={`border-b cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 ${
                  patent.patentNumber === expandedRow ? "border-b-0" : ""
                } last:border-b-0`}
                onClick={() => toggleExpansion(patent.patentNumber)}
              >
                <td className="text-center p-3">
                  <ProgressBar
                    featureScore={patent.coverage}
                    transform={true}
                    translateX="translate-x-[-22%]"
                    type="coverage"
                  />
                </td>
                <td className="text-center p-3">{patent.patentNumber}</td>
                <td className="text-center p-3">{patent.title}</td>
                <td className="text-center p-3">{truncateInventors(patent.inventors)}</td>
                <td className="text-left p-1">
                  <AbstractText limit={130} content={patent.abstract} />
                </td>
                <td className="text-center p-3">{patent.match}</td>
              </tr>
              <AnimatePresence>
                {expandedRow === patent.patentNumber && (
                  <tr className="">
                    <td colSpan={6} className="p-3  w-full">
                      <div className="overflow-hidden m-0 w-[100%]">
                        <AdditionalDetails patentDetail={patent} />
                      </div>
                    </td>
                  </tr>
                )}
              </AnimatePresence>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
