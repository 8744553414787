import { Button, Input } from "@nextui-org/react";
import {
  confirmResetPassword,
  ConfirmResetPasswordInput,
  resetPassword,
  type ResetPasswordOutput,
} from "aws-amplify/auth";
import { Dispatch, SetStateAction, useState } from "react";
import { IoEye, IoEyeOff, IoMail } from "react-icons/io5";
import { MdCheckCircleOutline, MdOutlineDoNotDisturbOn } from "react-icons/md";

export default function ForgotUserPassword(props: {
  email: string;
  updateAuthStatus: (arg0: boolean) => void;
  getUser: () => void;
  setCurrentStep: Dispatch<SetStateAction<string>>;
}) {
  const [email, setEmail] = useState<string>(props.email ? props.email : "");

  const [resetCodeFormError, setResetCodeFormError] = useState<string>("");
  const [resetCodeFormValidation, setResetCodeFormValidation] = useState({
    form: false,
    email: false,
  });
  const [resetCodeSubmitLoading, setResetCodeSubmitLoading] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<string>("");

  const [resetConfirmCode, setResetConfirmCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [resetSubmitLoading, setResetSubmitLoading] = useState(false);
  const [resetFormError, setResetFormError] = useState<string>("");
  const [resetFormValidation, setResetFormValidation] = useState({
    form: false,
    confirmCode: false,
    newPassword: false,
    passMinLength: false,
    passHasNumber: false,
    passHasLowerCase: false,
    passHasUpperCase: false,
    passHasSpecialChar: false,
  });

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateResetCodeForm = () => {
    setResetCodeFormError("");
    setResetFormError("");
    setResetCodeFormValidation((prevState) => ({
      ...prevState,
      email: false,
    }));
    const isEmailValid = validateEmail(email);
    setResetCodeFormValidation((prevState) => ({
      ...prevState,
      email: !isEmailValid,
    }));
    return isEmailValid;
  };

  async function handleResetPasswordCode() {
    if (!validateResetCodeForm()) return false;
    setResetCodeSubmitLoading(true);
    try {
      const output = await resetPassword({ username: email });
      handleResetPasswordNextSteps(output);
    } catch (error: any) {
      setResetCodeSubmitLoading(false);
      setResetCodeFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setResetCodeFormError(error.message);
    } finally {
      setResetCodeSubmitLoading(false);
    }
  }

  function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        setCurrentStep("CONFIRM_RESET_PASSWORD_WITH_CODE");
        break;
      case "DONE":
        props.updateAuthStatus(true);
        props.getUser();
        break;
    }
  }

  const validateNewPassword = (password: string) => {
    const isMinLengthValid = password.length >= 8;
    const isNumberValid = /\d/.test(password);
    const isLowerCaseValid = /[a-z]/.test(password);
    const isUpperCaseValid = /[A-Z]/.test(password);
    const isSpecialCharValid =
      /[\^$*\.\[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/.test(password) && /^[\S]+.*[\S]+$/.test(password);

    setResetFormValidation((prevState) => ({
      ...prevState,
      newPassword: false,
      passMinLength: !isMinLengthValid,
      passHasNumber: !isNumberValid,
      passHasLowerCase: !isLowerCaseValid,
      passHasUpperCase: !isUpperCaseValid,
      passHasSpecialChar: !isSpecialCharValid,
    }));

    return (
      isMinLengthValid &&
      isNumberValid &&
      isLowerCaseValid &&
      isUpperCaseValid &&
      isSpecialCharValid
    );
  };
  const validateResetForm = () => {
    setResetFormError("");
    setResetCodeFormError("");
    setResetFormValidation((prevState) => ({
      ...prevState,
      email: false,
      password: false,
    }));
    const isconfirmCodeValid = resetConfirmCode.trim() !== "";
    const isPasswordValid = newPassword.trim() !== "" && validateNewPassword(newPassword);
    setResetFormValidation((prevState) => ({
      ...prevState,
      confirmCode: !isconfirmCodeValid,
      newPassword: !isPasswordValid,
    }));
    return isconfirmCodeValid && isPasswordValid;
  };

  async function handleConfirmResetPassword() {
    if (!validateResetForm()) return;
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: resetConfirmCode,
        newPassword: newPassword,
      } as ConfirmResetPasswordInput);
    } catch (error: any) {
      setResetSubmitLoading(false);
      setResetFormValidation((prevState) => ({
        ...prevState,
        form: true,
      }));
      setResetFormError(error.message);
    } finally {
      setResetSubmitLoading(false);
    }
  }

  return (
    <section className="w-[320px] m-auto">
      <h1 className="text-3xl px-10 py-5 font-bold flex justify-center">Reset Password</h1>

      {currentStep !== "CONFIRM_RESET_PASSWORD_WITH_CODE" && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleResetPasswordCode();
          }}
        >
          <Input
            autoFocus
            isRequired
            classNames={{
              label: "text-base",
              input: "text-base",
              errorMessage: "text-md",
            }}
            endContent={
              <IoMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
            }
            label="Email address"
            variant="bordered"
            onValueChange={(value) => setEmail(value)}
            value={email}
            isInvalid={resetCodeFormValidation.email}
            errorMessage={
              email.trim() === ""
                ? "Email is required"
                : resetCodeFormValidation.email
                ? "Email is Invalid"
                : ""
            }
          />
          {resetCodeFormValidation.form && (
            <div className="text-danger text-sm pt-2 px-2">{resetCodeFormError}</div>
          )}
          <Button
            type="submit"
            className="my-5 text-base"
            fullWidth
            isLoading={resetCodeSubmitLoading}
          >
            Send Code
          </Button>
        </form>
      )}
      {currentStep === "CONFIRM_RESET_PASSWORD_WITH_CODE" && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirmResetPassword();
          }}
        >
          <Input
            autoFocus
            isRequired
            classNames={{
              label: "text-base",
              input: "text-base",
              errorMessage: "text-sm",
            }}
            label="Confirmation Code"
            variant="bordered"
            onValueChange={(value) => setResetConfirmCode(value)}
            value={resetConfirmCode}
            isInvalid={resetFormValidation.confirmCode}
            errorMessage={resetFormValidation.confirmCode ? "Confirmation code is required" : ""}
          />
          <Input
            className="mt-5 "
            classNames={{
              label: "text-base",
              input: "text-base",
              errorMessage: "text-md",
            }}
            endContent={
              showNewPassword ? (
                <IoEyeOff
                  className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              ) : (
                <IoEye
                  className="cursor-pointer text-2xl text-default-400 flex-shrink-0"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              )
            }
            onKeyUp={() => validateNewPassword(newPassword)}
            label="Password"
            variant="bordered"
            onValueChange={(value) => setNewPassword(value)}
            value={newPassword}
            type={showNewPassword ? "text" : "password"}
            isInvalid={resetFormValidation.newPassword}
            errorMessage={
              newPassword.trim() === ""
                ? "Password is required"
                : resetFormValidation.newPassword
                ? "Match Password Reqirements"
                : ""
            }
          />
          {resetFormValidation.form && (
            <div className="text-danger text-sm pt-2 px-2">
              {resetCodeFormError || resetFormError}
            </div>
          )}
          {newPassword.length > 0 && (
            <div className="text-md flex gap-3 flex-col pt-2 px-2">
              <div
                className={
                  "flex items-center gap-2 " +
                  (resetFormValidation.passMinLength ? "text-danger" : "text-success")
                }
              >
                {resetFormValidation.passMinLength ? (
                  <MdOutlineDoNotDisturbOn className="w-[5%]" />
                ) : (
                  <MdCheckCircleOutline className="w-[5%]" />
                )}
                8-character minimum length
              </div>

              <div
                className={
                  "flex items-center gap-2 " +
                  (resetFormValidation.passHasNumber ? "text-danger" : "text-success")
                }
              >
                {resetFormValidation.passHasNumber ? (
                  <MdOutlineDoNotDisturbOn className="w-[5%]" />
                ) : (
                  <MdCheckCircleOutline className="w-[5%]" />
                )}
                Contains at least 1 number
              </div>

              <div
                className={
                  "flex items-center gap-2 " +
                  (resetFormValidation.passHasLowerCase ? "text-danger" : "text-success")
                }
              >
                {resetFormValidation.passHasLowerCase ? (
                  <MdOutlineDoNotDisturbOn className="w-[5%]" />
                ) : (
                  <MdCheckCircleOutline className="w-[5%]" />
                )}
                Contains at least 1 lowercase letter
              </div>

              <div
                className={
                  "flex items-center gap-2 " +
                  (resetFormValidation.passHasUpperCase ? "text-danger" : "text-success")
                }
              >
                {resetFormValidation.passHasUpperCase ? (
                  <MdOutlineDoNotDisturbOn className="w-[5%]" />
                ) : (
                  <MdCheckCircleOutline className="w-[5%]" />
                )}
                Contains at least 1 uppercase letter
              </div>

              <div
                className={
                  "flex items-center gap-2 " +
                  (resetFormValidation.passHasSpecialChar ? "text-danger" : "text-success")
                }
              >
                {resetFormValidation.passHasSpecialChar ? (
                  <MdOutlineDoNotDisturbOn className="w-[18%]" />
                ) : (
                  <MdCheckCircleOutline className="w-[18%]" />
                )}
                Contains at least 1 special character or a non-leading, non-trailing space
                character. &#94; &#36; &#42; &#46; &#91; &#93; &#123; &#125; &#40; &#41; &#63; - " !
                @ # % & / \ , &#60; &#62; ' : ; | _ ~ ` + =
              </div>
            </div>
          )}
          <Button
            className="mt-2 text-base"
            fullWidth
            isLoading={resetCodeSubmitLoading}
            onPress={handleResetPasswordCode}
          >
            Resend Code
          </Button>
          <Button
            type="submit"
            isLoading={resetSubmitLoading}
            className="my-3 text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
            fullWidth
          >
            Reset Password
          </Button>
        </form>
      )}
    </section>
  );
}
