import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { useState } from "react";
import { Stars } from "./icons";
import { AutoGenerateFeaturesProps } from "../types/searchFormTypes";
import { getUserByAuthId, updateUser } from "../services/userService";
import { DocumentData } from "firebase/firestore";
import { CognitoUser } from "../types/cognitoUserType";

export default function AutoGenerateFeatures(props: AutoGenerateFeaturesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const minWords = 30;

  const [genFeaturesClicked, setGenFeaturesClicked] = useState<boolean>(false);
  const [userDoc, setUserDoc] = useState<DocumentData>();

  const checkTos = async (user?: CognitoUser): Promise<Boolean> => {
    if (user?.sub) {
      const fetchedUserDoc = await getUserByAuthId(user.sub);
      setUserDoc(fetchedUserDoc);
      if (fetchedUserDoc) {
        return fetchedUserDoc.acceptedAutoGenFeaturesTos === true;
      }
    }
    return false;
  };
  const handleAutoGenFeaturesTosAgree = async () => {
    await updateUser({ id: userDoc?.id, data: { acceptedAutoGenFeaturesTos: true } });
    onClose();
    handleAutoGenFeaturesClick();
  };

  const handleAutoGenFeaturesTosDisagree = async () => {
    await updateUser({ id: userDoc?.id, data: { acceptedAutoGenFeaturesTos: false } });
    onClose();
  };

  const handleAutoGenFeaturesClick = async () => {
    const tosAgreed = await checkTos(props.userIdentity);
    if (!tosAgreed) {
      onOpen();
    } else if (props.bigTextWordsRemaining >= minWords) {
      props.generateFeatures(true, false);
    } else {
      setGenFeaturesClicked(true);
    }
  };

  return (
    <div className="my-5 flex flex-col justify-center items-center">
      <Button
        className="text-base"
        onClick={handleAutoGenFeaturesClick}
        isLoading={props.featureGenerating === "primary"}
      >
        Auto-Generate Primary Features <Stars width={24} height={24} />
      </Button>
      {props.bigTextWordsRemaining < minWords && genFeaturesClicked && (
        <div className="text-red-500 mt-2">
          Please enter at least {minWords} words to enable feature generation.
        </div>
      )}
      <Modal
        size="5xl"
        scrollBehavior="inside"
        classNames={{ closeButton: "hidden" }}
        backdrop="blur"
        isOpen={isOpen}
        onClose={onClose}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
      >
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1 text-center">
              <h1 className="font-bold text-3xl">IntelliPat, Inc</h1>
              <h1 className="font-bold text-3xl">Auto Generate Features Terms and Conditions</h1>
            </ModalHeader>
            <ModalBody>
              <p>
                In connection with the 'Auto-Generate Primary Features' tool, IntelliPat utilizes
                OpenAI's ChatGPT API to assist with auto-generating primary features for
                patentability searching based on the user input data you provide on this page ('User
                Data' herein, which includes data provided in the 'Invention Description,'
                'Invention Type,' and 'Technology Area' fields). This process involves sending the
                User Data to ChatGPT's API via secure, encrypted communication channels, where it is
                processed using machine learning algorithms to analyze the User Data and return
                draft primary search features. The system leverages natural language processing to
                interpret the User Data and generate text content for use in patentability
                searching. This tool is designed to streamline the provision of search features by
                offering AI-assisted insights based on the User Data.
              </p>
              <p>
                We take the security and confidentiality of your data seriously. All information
                submitted to our system is encrypted during transmission using industry-standard
                protocols such as SSL and HTTPS. User Data is securely stored on servers with high
                levels of encryption to protect against unauthorized access. Furthermore, we do not
                share the User Data data with third parties, except for the necessary processing
                done by OpenAI's API, which operates under its own security and privacy policies (
                <a
                  className="underline"
                  href="https://openai.com/policies/service-terms/"
                  target="_blank"
                >
                  https://openai.com/policies/service-terms/
                </a>
                ) . Only authorized personnel have access to the User Data, and all efforts are made
                to ensure that the User Data remains private and protected.
              </p>
              <p>
                By using our 'Auto-Generate Primary Features' tool, you acknowledge and accept that
                while we employ rigorous security measures, no system is completely risk-free. You
                agree to use this service with the understanding that there may be associated risks
                regarding the confidentiality of the User Data data. You also release IntelliPat and
                its operators from any liabilities related to the security or confidentiality of the
                User Data, understanding that data sent to OpenAI for processing is subject to their
                security protocols, over which we have no direct control.
              </p>
              <p>
                As an alternative to auto-generating search features using the 'Auto-Generate
                Primary Features' tool, you may choose instead to utilize the 'Manually Input
                Features' option for search feature provision.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                className="text-base max-w-[20%] dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
                onPress={handleAutoGenFeaturesTosAgree}
              >
                Agree
              </Button>
              <Button className="text-base max-w-[20%]" onPress={handleAutoGenFeaturesTosDisagree}>
                Disagree
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </div>
  );
}
