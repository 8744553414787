import { Navbar, NavbarBrand, NavbarContent, NavbarItem, useDisclosure } from "@nextui-org/react";
import { useState } from "react";
import { Logo } from "./icons";
import { ThemeSwitch } from "./theme-switch";
import TermsAndConditions from "./termsAndConditions";
import { DocumentData } from "firebase/firestore";
import Auth from "./auth";

export default function NavbarIntellipat(props: {
  isAuthenticated: boolean;
  updateAuthStatus: (authStatus: boolean) => void;
  userIdentity: any;
  adminUser: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userDoc, setUserDoc] = useState<DocumentData>();

  return (
    <>
      <TermsAndConditions isOpen={isOpen} onClose={onClose} userDoc={userDoc} />
      <Navbar className="h-[100px]">
        <NavbarBrand className="items-end">
          <a href="/">
            <Logo size={66} />
          </a>
        </NavbarBrand>
        <NavbarContent className="hidden sm:flex basis-1/5 sm:basis-full" justify="end">
          <NavbarItem className="hidden sm:flex gap-2">
            <ThemeSwitch />
          </NavbarItem>
        </NavbarContent>
        <NavbarItem>
          <Auth
            isAuthenticated={props.isAuthenticated}
            userIdentity={props.userIdentity}
            adminUser={props.adminUser}
            updateAuthStatus={props.updateAuthStatus}
            onOpen={onOpen}
            setUserDoc={setUserDoc}
          />
        </NavbarItem>
        <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
          <ThemeSwitch />
        </NavbarContent>
      </Navbar>
    </>
  );
}
