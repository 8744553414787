import React, { useMemo, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Spinner,
  Button,
  Chip,
  Tooltip,
  getKeyValue,
  SortDescriptor,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { AuthUser, Item, Timestamp } from "../types/reportResultsTypes";
import { format } from "date-fns";
import useSWR from "swr";
import { getAllInputSearch } from "../services/inputSearchService";
import DownloadJsonButton from "./DownloadJsonButton2";
import { IoMdClipboard } from "react-icons/io";
import ProgressBar from "./ProgressBar";
import { LoadingState } from "@react-types/shared";

export default function ReportResultsTable({ currentAuthUser }: { currentAuthUser: AuthUser }) {
  const [reportPage, setReportPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<Timestamp | null>(null);
  const [allReports, setAllReports] = useState<Item[]>([]);
  const [reportLoading, setReportLoading] = useState<LoadingState>("loadingMore");
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>();
  const rowsPerPage = 10;

  const fetcher = async (
    authUser: AuthUser,
    rowsPerPage: number,
    lastVisible: Timestamp | null
  ) => {
    const { Reports, totalCount, lastVisibleDoc } = await getAllInputSearch(
      authUser,
      rowsPerPage,
      lastVisible
    );
    if (lastVisibleDoc === null) {
      return;
    }
    const inputSearch: Item[] = Reports.map((input) => ({
      ...input,
      ...(input.metadata ? JSON.parse(input.metadata) : {}),
    }));
    return { inputSearch, totalCount, lastVisibleDoc };
  };

  const { data } = useSWR(
    [`/api/reports`, currentAuthUser, rowsPerPage, lastVisible],
    () => fetcher(currentAuthUser, rowsPerPage, lastVisible),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data) {
          setLastVisible(data.lastVisibleDoc);
          setAllReports((prevReports) => [...prevReports, ...data.inputSearch]);
          setReportLoading("idle");
        } else {
          setReportLoading("idle");
        }
      },
    }
  );

  const navigate = useNavigate();
  function handleSearchAgain(item: Item) {
    return () => navigate("/search", { state: { ...item } });
  }

  const formatDate = (datetime: Timestamp) => {
    const date = new Date(datetime.seconds * 1000);
    const month = format(date, "MMM");
    const fullMonth = format(date, "MMMM");
    const restOfTheDate = format(date, "dd, yyyy");
    return fullMonth.length > 3 ? `${month}. ` + restOfTheDate : `${month} ` + restOfTheDate;
  };

  const totalCount = data?.totalCount || 0;

  const sortedReports = useMemo(() => {
    if (!sortDescriptor?.column) return allReports;

    const sorted = [...allReports].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof Item];
      const second = b[sortDescriptor.column as keyof Item];

      let cmp =
        (parseInt(first as string) || (first as keyof Item)) <
        (parseInt(second as string) || (second as keyof Item))
          ? -1
          : 1;

      if (sortDescriptor.direction === "descending") {
        cmp *= -1;
      }

      return cmp;
    });

    return sorted;
  }, [allReports, sortDescriptor]);

  const reports = useMemo(
    () => sortedReports.slice((reportPage - 1) * rowsPerPage, reportPage * rowsPerPage),
    [sortedReports, reportPage, rowsPerPage]
  );

  const totalPages = useMemo(() => Math.ceil(totalCount / rowsPerPage), [totalCount, rowsPerPage]);

  const copyIdToClipboard = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
    } catch (err) {
      console.error("Failed to copy Id:", err);
    }
  };

  const renderTableColumn = (item: Item, columnKey: keyof Item): React.ReactNode => {
    switch (columnKey) {
      case "created_at":
        return formatDate(item.created_at);

      case "id":
        return (
          <Tooltip showArrow={true} content={item.id} placement="top-start" offset={-7}>
            <Button variant="light" size="sm" onPress={() => copyIdToClipboard(item.id)}>
              <IoMdClipboard className="text-lg" />
            </Button>
          </Tooltip>
        );

      case "status":
        return (
          <div className="flex">
            <Chip className="bg-default">
              {item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1) : "-"}
            </Chip>
          </div>
        );

      case "results":
        return (
          <div className="flex">
            {item.status === "done" && (
              <Button className="mr-2" size="sm" onClick={() => navigate(`${item.id}`)}>
                View
              </Button>
            )}
            <DownloadJsonButton
              item={item}
              fileName={`intellipat_report-${item.titleMatter}.pdf`}
            />
          </div>
        );

      default:
        return getKeyValue(item, columnKey) || "-";
    }
  };

  const handleSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
  };

  return (
    <Table
      aria-label="Example table with client async pagination"
      selectionMode="single"
      sortDescriptor={sortDescriptor}
      onSortChange={handleSortChange}
      bottomContent={
        <div className="flex gap-2 justify-end items-center">
          <span>{`Displaying ${(reportPage - 1) * rowsPerPage + 1} to ${Math.min(
            reportPage * rowsPerPage,
            totalCount
          )} results out of ${totalCount}`}</span>
          {totalPages > 1 && (
            <Pagination
              loop
              isCompact
              showControls
              showShadow
              classNames={{
                cursor: "text-white dark:bg-[#d1d5db] dark:text-black bg-[#1e293b]",
              }}
              page={reportPage}
              total={totalPages}
              onChange={(page) => setReportPage(page)}
            />
          )}
        </div>
      }
      classNames={{
        wrapper: "min-h-[222px]",
      }}
    >
      <TableHeader>
        <TableColumn className="text-md text-light font-bold" key="created_at" allowsSorting>
          Created At
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="id" allowsSorting>
          Job ID
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="titleMatter" allowsSorting>
          Title/Matter Number
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="status" allowsSorting>
          Process Status
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="noveltyScore">
          Novelty Score
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="results">
          Results
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="searchAgain">
          Search again
        </TableColumn>
      </TableHeader>
      <TableBody
        items={reports}
        loadingState={reportLoading}
        loadingContent={<Spinner label="Loading..." />}
        emptyContent={"No reports to display."}
      >
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => {
              if (columnKey === "noveltyScore") {
                return (
                  <TableCell key="noveltyScore">
                    {Object.keys(item.results).length > 0 ? (
                      <ProgressBar
                        featureScore={JSON.parse(item.results.metadata).noveltyScore}
                        type="novelty"
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                );
              }
              if (columnKey === "searchAgain") {
                return (
                  <TableCell key="searchAgain">
                    <Button onPress={handleSearchAgain(item)}>Search Again</Button>
                  </TableCell>
                );
              }
              if (columnKey in item) {
                return (
                  <TableCell key={columnKey}>
                    {renderTableColumn(item, columnKey as keyof Item)}
                  </TableCell>
                );
              }
              return <TableCell key={columnKey}>-</TableCell>;
            }}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
